export enum MENU_TYPES {
  DRIVERS,
  NETWORK,
  BDCON,
  HARDWARE,
  TEST,
  PASSWORD,
}

export const MENU: { [key in MENU_TYPES]: { text: string; path: string } } = {
  [MENU_TYPES.DRIVERS]: { text: "Drivers", path: "/main" },
  [MENU_TYPES.NETWORK]: { text: "Network Settings", path: "/settings/network" },
  [MENU_TYPES.BDCON]: { text: "BDCon Settings", path: "/settings/bdcon" },
  [MENU_TYPES.HARDWARE]: { text: "Hardware Settings", path: "/settings/hardware" },
  [MENU_TYPES.TEST]: { text: "Test", path: "/test" },
  [MENU_TYPES.PASSWORD]: { text: "Password Settings", path: "/settings/password" },
};
