import { useContext, useEffect, useRef, useState } from "react";
import Heading3 from "../shared/components/Heading3";
import SecondaryButton from "../shared/components/SecondaryButton";
import Input from "../shared/components/Input";
import DangerButton from "../shared/components/DangerButton";
import PrimaryButton from "../shared/components/PrimaryButton";
import Heading2 from "../shared/components/Heading2";
import { UrlContext } from "../App";
import { GetApiAsync, PostApiAsync } from "../api";
import { LOADING_STATUS } from "../shared/constants/status";
import InfoMessage from "../shared/components/InfoMessage";
import ErrorMessage from "../shared/components/ErrorMessage";
import { getAccessToken } from "../auth/SessionManager";

const Test = () => {
  const [form, setForm] = useState({
    selectedDeviceId: 1,
    ADCTime: "",
    ADCValue: "",
    ROUTTime: "",
    ROUTValue: "",
  });

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [tty1, setTty1] = useState("");
  const [tty2, setTty2] = useState("");
  const [message, setMessage] = useState("");
  const [toastText, setToastText] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const host = useContext(UrlContext);
  let ws = useRef<WebSocket | null>(null);
  let status = useRef<LOADING_STATUS>("LOADING");
  let enableTest = useRef(false);

  const onStart = async () => {
    setToastText("starting..");
    const { data } = await PostApiAsync(`${host}/api/Test/Start`, {});
    if (data) {
      setToastText("success");
      fetch();
    }
  };
  const onStop = async () => {
    setToastText("stopping..");
    const { data } = await PostApiAsync(`${host}/api/Test/Stop`, {});
    if (data) {
      setToastText("success");
      fetch();
    }
  };
  const onPostData = async () => {
    const { data } = await PostApiAsync(`${host}/api/Test/Data`, { selectedDeviceId: form.selectedDeviceId, message });
    if (data) {
      setToastText("success");
    }
  };
  const onToggle = async () => {
    const { data } = await PostApiAsync(`${host}/api/Test/Toggle`, {});
    if (data) {
      setToastText("success");
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      //      console.log("clean up");
      setInfoMsg("socket disconnected.");
      ws.current?.close();
      ws.current = null;
      setSocketConnected(false);
    };
  }, []);

  useEffect(() => {
    if (toastText) {
      setTimeout(() => {
        setToastText(null);
      }, 3500);
    }
  }, [toastText]);

  useEffect(() => {
    if (socketConnected) {
      return;
    }

    if (!ws.current) {
      const accesstoken = getAccessToken();
      document.cookie = 'Authorization='+accesstoken+'; SameSite=Strict';
      const url = `ws://${host?.split("://")[1]}`+(process.env.REACT_APP_DEV_SERVER_URL===undefined?'':`?Authorization=${accesstoken}`);
      console.log(url);
      ws.current = new WebSocket(url);
      ws.current.onopen = () => {
        setInfoMsg("socket connected");
        setSocketConnected(true);
      };
      ws.current.onclose = (error) => {
        //console.log("disconnect from ", error);
        setInfoMsg("socket disconnected");
        ws.current = null;
        setSocketConnected(false);
      };
      ws.current.onerror = (error) => {
        console.log("connection error ", error);
        setInfoMsg("socket connection error");
      };
      ws.current.onmessage = (event) => {
        if (status.current !== "LOADED" || enableTest.current === false)
          return;


        const ttyS1 = "/dev/ttyS1";
        const ttyS2 = "/dev/ttyS2";
        const adc = "ADC";
        const rout = "ROUT";
        //console.log("json", event.data);
        let json;
        try {
          json = JSON.parse(event.data);
        } catch {
          return;
        }

        if (!json.Type.includes("ConsoleOutput")) return;

        if (!Array.isArray(json.Data) && typeof json.Data.DeviceName !== "undefined") {
          if (json.Data.DeviceName === rout) {
            let splits = json.Data.Message.split(",");
            for (let i = 0; i < splits.length; i++) {
              let data = splits[i].split("=");
              if (data.length !== 2) continue;

              const dev = data[0];
              const value = data[1];
              if (dev === "data") {
                setForm((prev) => ({ ...prev, ROUTValue: value }));
              } else if (dev === "time") {
                setForm((prev) => ({ ...prev, ROUTTime: value }));
              }
            }
            return;
          } else if (json.Data.DeviceName === adc) {
            let splits = json.Data.Message.split(",");
            for (let i = 0; i < splits.length; i++) {
              let data = splits[i].split("=");
              if (data.length !== 2) continue;

              const dev = data[0];
              const value = data[1];
              if (dev === "data") {
                setForm((prev) => ({ ...prev, ADCValue: value }));
              } else if (dev === "time") {
                setForm((prev) => ({ ...prev, ADCTime: value }));
              }
            }
            return;
          } else if (json.Data.DeviceName === ttyS1) {
            setTty1((prev) => (prev + json.Data.Message + "\n").substring((prev + json.Data.Message + "\n").length - 2000));
          } else if (json.Data.DeviceName === ttyS2) {
            setTty2((prev) => (prev + json.Data.Message + "\n").substring((prev + json.Data.Message + "\n").length - 2000));
          } else return;

          // let length;
          // if (typeof textarea !== "undefined") {
          //   length = textarea.value.length;
          // }
          // if (length > 2000) textarea.val(textarea.value.substr(length - 2000) + "\n" + json.Message);
          // else textarea.val(textarea.value + "\n" + json.Message);
          // textarea.scrollTop(textarea[0].scrollHeight - textarea.height());
        }
      };
    }
  }, [socketConnected]);

  const fetch = async () => {
    status.current = "LOADING";
    const result = await GetApiAsync(`${host}/api/Test/EnableTest`);

    if (enableTest.current !== result.data) {
      enableTest.current = result.data;
    }
    status.current = "LOADED";
  };

  return (
    <div className="space-y-12 relative">
      <div className="space-y-1">
        <Heading2>Test</Heading2>
        {infoMsg && <InfoMessage>{infoMsg}</InfoMessage>}
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <div className="flex items-center space-x-2">
          {enableTest.current ? (
            <DangerButton onClick={onStop}>Stop</DangerButton>
          ) : (
            <SecondaryButton onClick={onStart}>Start</SecondaryButton>
          )}
          <p>{toastText}</p>
        </div>
      </div>
      <div className="space-y-4">
        <Heading3>ADC</Heading3>
        <div className="flex items-center space-x-4">
          <p>ADCTime</p>
          <Input disabled id="ADCTime" value={form.ADCTime} />
        </div>
        <div className="flex items-center space-x-4">
          <p>ADCValue</p>
          <Input disabled id="ADCValue" value={form.ADCValue} />
        </div>
      </div>

      <div className="space-y-4">
        <Heading3>ROUT</Heading3>
        <div className="flex items-center space-x-4">
          <p>ROUTTime</p>
          <Input disabled id="ROUTTime" value={form.ROUTTime} />
        </div>
        <div className="flex items-center space-x-4">
          <p>ROUTValue</p>
          <Input disabled id="ROUTValue" value={form.ROUTValue} />
        </div>
        <PrimaryButton onClick={onToggle}>Toggle</PrimaryButton>
      </div>

      <div className="space-y-4">
        <Heading3>SendMessage</Heading3>
        <Input placeholder="입력하세요.." value={message} onChange={(e) => setMessage(e.target.value)} />

        <div className="flex items-center space-x-4">
          {/* 이거 두개뿐인가? */}
          <select
            className="min-w-48 px-2 py-1 bg-gray-50 border border-gray-300 rounded-sm focus:outline-none"
            value={form.selectedDeviceId}
            onChange={(e) => setForm({ ...form, selectedDeviceId: +e.target.value })}
          >
            <option value={1}>/dev/ttyS1</option> <option value={2}>/dev/ttyS2</option>
          </select>
          <PrimaryButton onClick={onPostData}>Send data</PrimaryButton>
        </div>
        <div className="grid grid-cols-2 gap-x-2">
          <div className="">
            <p className="font-semibold">/dev/ttyS1</p>
            <textarea
              className="w-full bg-gray-50 resize-none border border-gray-300 px-2 py-1"
              disabled
              rows={15}
              value={tty1}
            ></textarea>
          </div>
          <div className="">
            <p className="font-semibold">/dev/ttyS2</p>
            <textarea
              className="w-full bg-gray-50 resize-none border border-gray-300 px-2 py-1"
              disabled
              rows={15}
              value={tty2}
            ></textarea>
          </div>
        </div>
      </div>
      {/* <Toast text={toastText} /> */}
    </div>
  );
};

export default Test;
