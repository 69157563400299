import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import BDCon from "./pages/BDCon";
import Hardware from "./pages/Hardware";
import Header from "./shared/components/Header";
import Password from "./pages/Password";
import SignIn from "./pages/SignIn";
import { MENU, MENU_TYPES } from "./shared/constants/menu";
import { getAccessToken } from "./auth/SessionManager";
import { createContext } from "react";
import Test from "./pages/Test";
import NetworkSettingsContainer from "./pages/NetworkSettings";

export const UrlContext = createContext(process.env.REACT_APP_DEV_SERVER_URL ?? window.location.origin);

function App() {
  return (
    <UrlContext.Provider value={process.env.REACT_APP_DEV_SERVER_URL ?? window.location.origin}>
      <BrowserRouter>
        <Header />
        <div className="py-16 px-3 md:px-5 lg:max-w-[1024px] mx-auto">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <NetworkSettingsContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.DRIVERS].path}
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.NETWORK].path}
              element={
                <ProtectedRoute>
                  <NetworkSettingsContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.BDCON].path}
              element={
                <ProtectedRoute>
                  <BDCon />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.HARDWARE].path}
              element={
                <ProtectedRoute>
                  <Hardware />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.TEST].path}
              element={
                <ProtectedRoute>
                  <Test />
                </ProtectedRoute>
              }
            />
            <Route
              path={MENU[MENU_TYPES.PASSWORD].path}
              element={
                <ProtectedRoute>
                  <Password />
                </ProtectedRoute>
              }
            />
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        </div>
      </BrowserRouter>
    </UrlContext.Provider>
  );
}

export default App;

const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  if (getAccessToken()) return children;
  return <Navigate to="/signin" />;
};
