import Heading2 from "../../shared/components/Heading2";
import Input from "../../shared/components/Input";
import SecondaryButton from "../../shared/components/SecondaryButton";
import PrimaryButton from "../../shared/components/PrimaryButton";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import ErrorMessage from "../../shared/components/ErrorMessage";
import InfoMessage from "../../shared/components/InfoMessage";
import IPInput from "../../shared/components/IPInput";
import Heading3 from "../../shared/components/Heading3";
import useSettings from "./useSettings";
import { WifiSetting } from "../../shared/constants/settings";

const NetworkSettingsContainer = () => {
  const {
    status,
    deviceType,
    nw,
    targetInterface,
    setTargetInterface,
    useDhcp,
    setUseDhcp,
    wf,
    setWf,
    ap,
    setAp,
    isScannable,
    wfList,
    infoMsg,
    apInfoMsg,
    handleIpInputChange,
    handleNetworkSettingIpInputChange,
    handleDhcpServerSettingIpInputChange,
    handleApplyClick,
    handleResetClick,
    handleWifiScanClick,
    handleSsidRowClick,
    handleWifiChangeSave,
    handleApChangeSave,
  } = useSettings();

  if (status === "LOADED")
    return (
      <div className="space-y-12 relative">
        <div className="space-y-2">
          <Heading2>BDCon network Settings</Heading2>
          <p className="font-sm text-gray-700 bg-gray-50 rounded-lg inline">{deviceType}</p>
          <div className="flex items-center space-x-2">
            <p>Select interface: </p>
            <select
              className="min-w-48 px-2 py-1 bg-gray-50 border border-gray-300 rounded-sm focus:outline-none"
              value={targetInterface?.networkInterfaceId ?? ""}
              onChange={(e) => setTargetInterface(nw?.networkInformations[e.target.value] ?? null)}
            >
              {nw?.networkInformations &&
                Object.keys(nw.networkInformations)
                  .filter((x) => x !== "wlan0_ap" && x !== "w_ap")
                  .map((x, key) => (
                    <option key={key} value={x}>
                      {x}
                    </option>
                  ))}
            </select>
            <SecondaryButton onClick={handleResetClick}>Reset</SecondaryButton>
          </div>
        </div>
        <div className="space-y-4">
          <Heading3>Network information</Heading3>
          <div className="flex items-center space-x-4">
            <p>IP Address</p>
            <IPInput disabled={true} name="ip" ip={targetInterface?.ip ?? null} onChangeIp={handleIpInputChange} />
          </div>
          <div className="flex items-center space-x-4">
            <p>Subnet mask</p>
            <IPInput
              disabled={true}
              name="subnetMask"
              ip={targetInterface?.subnetMask ?? null}
              onChangeIp={handleIpInputChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <p>Gateway</p>
            <IPInput
              disabled={true}
              name="gateway"
              ip={targetInterface?.gateway ?? null}
              onChangeIp={handleIpInputChange}
            />
          </div>
        </div>
        <div className="space-y-4">
          <Heading3>BDConNetwork Setting</Heading3>
          <div className="space-x-12">
            <label>
              DHCP
              <input
                type="radio"
                name="isDhcp"
                value="DHCP"
                className="ml-2"
                checked={targetInterface?.networkSetting?.isDhcpEnabled}
                onChange={() =>
                  setTargetInterface({
                    ...targetInterface,
                    networkSetting: { ...targetInterface?.networkSetting, isDhcpEnabled: true },
                  })
                }
              />
            </label>
            <label>
              Static IP
              <input
                type="radio"
                name="isDhcp"
                value="Static"
                className="ml-2"
                checked={!targetInterface?.networkSetting?.isDhcpEnabled}
                onChange={() =>
                  setTargetInterface({
                    ...targetInterface,
                    networkSetting: { ...targetInterface?.networkSetting, isDhcpEnabled: false },
                  })
                }
              />
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <p>IP Address</p>
            <IPInput
              disabled={targetInterface?.networkSetting?.isDhcpEnabled}
              name="ip"
              ip={targetInterface?.networkSetting?.ip ?? null}
              onChangeIp={handleNetworkSettingIpInputChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <p>Subnet mask</p>
            <IPInput
              disabled={targetInterface?.networkSetting?.isDhcpEnabled}
              name="subnetMask"
              ip={targetInterface?.networkSetting?.subnetMask ?? null}
              onChangeIp={handleNetworkSettingIpInputChange}
            />
          </div>
          <div className="flex items-center space-x-4">
            <p>Gateway</p>
            <IPInput
              disabled={targetInterface?.networkSetting?.isDhcpEnabled}
              name="gateway"
              ip={targetInterface?.networkSetting?.gateway ?? null}
              onChangeIp={handleNetworkSettingIpInputChange}
            />
          </div>
        </div>
        <div className="space-y-4">
          <div className="form-group form-inline font-bold">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="mr-2 w-6 h-6"
                checked={useDhcp}
                disabled={targetInterface?.networkSetting?.isDhcpEnabled}
                onChange={() => setUseDhcp(!useDhcp)}
              />
              Use DHCP Server
            </label>
          </div>
          {useDhcp && (
            <>
              <Heading3>DHCPServer Setting</Heading3>
              <div className="flex items-center space-x-4">
                <p>IP Address</p>
                <IPInput name="usbLanIPAddress" ip={targetInterface?.dhcpServerSetting?.ip ?? null} disabled />
              </div>
              <div className="flex items-center space-x-4">
                <p>DHCP Range Start</p>
                <IPInput
                  name="dhcpRangeStart"
                  ip={targetInterface?.dhcpServerSetting?.dhcpRangeStart ?? null}
                  onChangeIp={handleDhcpServerSettingIpInputChange}
                />
              </div>
              <div className="flex items-center space-x-4">
                <p>DHCP Range End</p>
                <IPInput
                  name="dhcpRangeEnd"
                  ip={targetInterface?.dhcpServerSetting?.dhcpRangeEnd ?? null}
                  onChangeIp={handleDhcpServerSettingIpInputChange}
                />
              </div>
            </>
          )}
        </div>

        <PrimaryButton
          className="sticky bottom-3 p-3 bg-gradient-to-r from-green-400 to-blue-500 hover:bg-blue-600 rounded-lg text-lg font-bold text-white focus:outline-none drop-shadow-lg w-full disabled:bg-none disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed"
          onClick={handleApplyClick}
          disabled={!targetInterface?.networkInterfaceId}
        >
          Apply {targetInterface?.networkInterfaceId ?? ""}
        </PrimaryButton>

        <div className="space-y-4">
          <Heading3>WiFi Settings</Heading3>
          {!wf && <ErrorMessage>wifi 정보가 없습니다</ErrorMessage>}
          {infoMsg && <InfoMessage>{infoMsg}</InfoMessage>}
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>SSID</p>
            <Input
              disabled={!wf}
              value={wf?.wlanSSID??""}
              onChange={(e) => setWf({ ...wf, wlanSSID: e.target.value } as WifiSetting)}
            />
          </div>
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>Password</p>
            <Input
              disabled={!wf}
              value={wf?.wlanPassword??""}
              onChange={(e) => setWf({ ...wf, wlanPassword: e.target.value } as WifiSetting)}
            />
          </div>
          <div className="space-x-2">
            <PrimaryButton onClick={handleWifiChangeSave}>Save Wifi</PrimaryButton>
            <SecondaryButton onClick={handleWifiScanClick} disabled={!isScannable}>
              Scan Networks
            </SecondaryButton>
          </div>

          <div>
            {wfList.length > 0 && (
              <div>
                <p>행을 클릭하면 설정됩니다</p>
                <table>
                  <thead>
                    <tr>
                      <th className="border border-gray-300 bg-gray-200">ssid</th>
                      <th className="border border-gray-300 bg-gray-200">signal strength</th>
                      {/* <th className="border border-gray-300 bg-gray-200">is unicode ssid</th>
                        <th className="border border-gray-300 bg-gray-200">raw ssid</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {wfList.map((x) => (
                      <tr
                        key={x.ssid}
                        className="hover:cursor-pointer hover:bg-blue-50"
                        onClick={() => handleSsidRowClick(x.ssid)}
                      >
                        <td className="border border-gray-300 p-1">{x.ssid}</td>
                        <td className="border border-gray-300 p-1">
                          {x.signalStrength}
                          {x.signalStrength > 0 ? "%" : "dBm"}
                        </td>
                        {/* <td className="border border-gray-300 p-1">{x.isUnicodeSSID === true ? "" : "❌"}</td>
                          <td className="border border-gray-300 p-1">{x.rawSSID}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <Heading3>AP Settings</Heading3>
          {apInfoMsg && <InfoMessage>{apInfoMsg}</InfoMessage>}

          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>AP Name</p>
            <Input disabled={!ap} value={ap?.apName??""} onChange={(e) => setAp({ ...ap, apName: e.target.value })} />
          </div>
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>AP Password</p>
            <Input
              disabled={!ap}
              value={ap?.apPassword??""}
              onChange={(e) => setAp({ ...ap, apPassword: e.target.value })}
            />
          </div>
          <PrimaryButton onClick={handleApChangeSave}>Save AP</PrimaryButton>
        </div>
      </div>
    );
  else return <LoadingIndicator />;
};

export default NetworkSettingsContainer;
