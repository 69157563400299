const DangerButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="px-4 py-2 bg-rose-100 hover:bg-rose-200 rounded-lg text-lg font-bold text-rose-600 focus:outline-none "
      {...props}
    />
  );
};

export default DangerButton;
