import { useContext, useState } from "react";
import Heading2 from "../shared/components/Heading2";
import Input from "../shared/components/Input";
import SecondaryButton from "../shared/components/SecondaryButton";
import { PostApiAsync } from "../api";
import { UrlContext } from "../App";

const Password = () => {
  const [description, setDescription] = useState("");
  const [pw, setPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const host = useContext(UrlContext);

  const onSave = async () => {
    if (pw !== newPw) {
      setDescription("비밀번호가 다릅니다");
      return;
    }

    const result = await PostApiAsync(`${host}/api/Password`, { newPassword: newPw });
    if (result.data) {
      setDescription("변경 성공");
    }
  };

  return (
    <div className="space-y-8">
      <Heading2>Change Password</Heading2>

      <div>
        <p>New:</p>
        <Input type="password" value={pw} onChange={(e) => setPw(e.target.value)} />
      </div>

      <div>
        <p>Re-type New:</p>
        <Input type="password" value={newPw} onChange={(e) => setNewPw(e.target.value)} />
      </div>

      <SecondaryButton onClick={onSave}>Save</SecondaryButton>
      <p className="text-rose-500">{description}</p>
    </div>
  );
};

export default Password;
