const PrimaryButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-lg font-bold text-white focus:outline-none hover:cursor-pointer"
      {...props}
    />
  );
};

export default PrimaryButton;
