import { useEffect, useState } from "react";
import SecondaryButton from "../shared/components/SecondaryButton";
import Heading2 from "../shared/components/Heading2";
import axios from "axios";

type ECHO_SERVER_OPTIONS = "ON" | "OFF" | null;

const Hardware = () => {
  const [formData, setFormData] = useState({ isWorking: false, usingEchoSerialServer: false });
  const [option, setOption] = useState<ECHO_SERVER_OPTIONS>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //axios
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <Heading2>EchoSerialServer</Heading2>
      <div className="space-y-4">
        <div className="flex items-center space-x-4">
          <p>Enable</p>
          <input disabled type="checkbox" checked={formData.usingEchoSerialServer} />
        </div>
        <div className="flex items-center space-x-4">
          <p>Working</p>
          <input disabled type="checkbox" checked={formData.isWorking} />
        </div>
      </div>
      <div className="space-x-2">
        <SecondaryButton onClick={() => setOption("ON")}>켜기</SecondaryButton>
        <SecondaryButton onClick={() => setOption("OFF")}>끄기</SecondaryButton>
      </div>
    </form>
  );
};

export default Hardware;
