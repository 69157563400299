const SecondaryButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="px-4 py-2 bg-blue-100 hover:bg-blue-200 rounded-lg text-lg font-bold text-blue-600 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
      {...props}
    />
  );
};

export default SecondaryButton;
