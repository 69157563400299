import axios from "axios";
import { getAccessToken } from "../auth/SessionManager";

export const setAccessToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const PostApiAsync = async (path: string, body: object, sendAuthorizationHeader = false) => {
  const token = getAccessToken();
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const result = await axios.post(path, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (result.status === 401) {
    window.location.href = `${process.env.REACT_APP_DEV_SERVER_URL ?? window.location.origin}/signin`;
  }

  return result;
};

export const GetApiAsync = async (path: string, parameters?: object) => {
  const token = getAccessToken();
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const result = await axios.get(path, {
    params: parameters,
  });

  if (result.status === 401) {
    window.location.href = `${process.env.REACT_APP_DEV_SERVER_URL ?? window.location.origin}/signin`;
  }

  return result;
};
