import { useContext, useEffect, useState } from "react";
import { GetApiAsync, PostApiAsync } from "../../api";
import { UrlContext } from "../../App";
import { ApSetting, WifiScan, WifiSetting } from "../../shared/constants/settings";
import { LOADING_STATUS } from "../../shared/constants/status";
import { BDConNetworkInformations, NetworkInformation, Speed } from "../../shared/constants/settings";

const useSettings = () => {
  const host = useContext(UrlContext);
  const [deviceType, setDeviceType] = useState("");
  const [status, setStatus] = useState<LOADING_STATUS>("LOADING");
  const [nw, setNw] = useState<BDConNetworkInformations | null>(null);
  const [targetInterface, setTargetInterface] = useState<NetworkInformation | null>(null);
  const [wf, setWf] = useState<WifiSetting>({ wlanSSID: "", wlanPassword: "" });
  const [ap, setAp] = useState<ApSetting>({ apName: "", apPassword: "" });

  const [useDhcp, setUseDhcp] = useState(false);
  const [isScannable, setIsScannable] = useState(false);

  const [wfList, setWfList] = useState<Array<WifiScan>>([]);
  const [infoMsg, setInfoMsg] = useState("");
  const [apInfoMsg, setApInfoMsg] = useState("");

  useEffect(() => {
    fetchNetworkManager();
    fetchType();
  }, []);

  useEffect(() => {
    console.log("traget interface id changed:", targetInterface?.networkInterfaceId);
    if (targetInterface?.networkInterfaceId) setUseDhcp(targetInterface.dhcpServerSetting != null);
  }, [targetInterface?.networkInterfaceId]);

  const fetchNetworkManager = async () => {
    setStatus(() => "LOADING");
    const { data } = await GetApiAsync(`${host}/api/Network`);

    setNw(data);
    setIsScannable(
      data.networkInformations && (data.networkInformations["wlan0_station"] || data.networkInformations["w_st"])
    );
    setAp(data.apSetting);
    setWf(data.wifiSetting);
    if (data.networkInformations) {
      setTargetInterface(data.networkInformations[Object.keys(data.networkInformations)[0]]);
      setUseDhcp(data.networkInformations[Object.keys(data.networkInformations)[0]].dhcpServerSetting != null);
    }
    setStatus(() => "LOADED");
  };

  const fetchType = async () => {
    const { data } = await GetApiAsync(`${host}/api/Home/DeviceType`);
    if (data) {
      setDeviceType(data);
    }
  };

  const handleIpInputChange = ({ name, updated }: { name: string; updated: string }) => {
    setTargetInterface({ ...targetInterface, [name]: updated } as NetworkInformation);
  };

  const handleNetworkSettingIpInputChange = ({ name, updated }: { name: string; updated: string }) => {
    setTargetInterface({
      ...targetInterface,
      networkSetting: { ...targetInterface.networkSetting, [name]: updated },
    } as NetworkInformation);
  };

  const handleDhcpServerSettingIpInputChange = ({ name, updated }: { name: string; updated: string }) => {
    setTargetInterface({
      ...targetInterface,
      dhcpServerSetting: {
        ip: targetInterface.networkSetting.ip,
        networkInterfaceId: targetInterface.networkInterfaceId,
        ...targetInterface.dhcpServerSetting,
        [name]: updated,
      },
    } as NetworkInformation);
  };

  const handleApplyClick = async () => {
    if (!targetInterface) {
      alert("선택된 인터페이스가 없습니다");
      return;
    }

    console.log("post interface", targetInterface);

    const result = await PostApiAsync(`${host}/api/Network/ChangeNetworkSetting`, {
      ...targetInterface,
      dhcpServerSetting: useDhcp ? targetInterface.dhcpServerSetting : null,
    });
    console.log("applyreslt", result.data);
  };

  const handleResetClick = async () => {
    if (!targetInterface) {
      alert("선택된 인터페이스가 없습니다");
      return;
    }

    if (!window.confirm("정말 리셋하시겠습니까?")) return;

    const { data } = await PostApiAsync(`${host}/api/USBReset?interfacename=${targetInterface.networkInterfaceId}`, {});
  };

  const handleWifiScanClick = async () => {
    const { data } = await GetApiAsync(`${host}/api/Network/WifiScan`);
    console.log("wifiscan", data);

    if (!data) {
      alert("내역이 없습니다");
    }

    setWfList(data);
  };

  const handleSsidRowClick = (selected: string) => {
    setWf({ wlanSSID: selected, wlanPassword: "" });
  };

  const handleWifiChangeSave = async () => {
    if (!wf) {
      alert("wf 정보가 없습니다");
      return;
    }

    const { data } = await PostApiAsync(`${host}/api/Network/ChangeWifiSetting`, {
      wlanSSID: wf.wlanSSID,
      wlanPassword: wf.wlanPassword,
    });

    if (data.wlanSSID === wf.wlanSSID && data.wlanPassword === wf.wlanPassword) {
      setInfoMsg("Wifi 저장 성공");
    } else {
      alert("Fail!");
      setWf({ ...data });
    }
  };

  const handleApChangeSave = async () => {
    if (!ap) {
      alert("ap 정보가 없습니다");
      return;
    }

    const { data } = await PostApiAsync(`${host}/api/Network/ChangeApSetting`, {
      apName: ap.apName,
      apPassword: ap.apPassword,
    });

    if (data.apName === ap.apName && data.apPassword === ap.apPassword) {
      setApInfoMsg("Ap 저장 성공");
    } else {
      alert("Fail!");
      setAp({ ...data });
    }
  };

  return {
    status,
    deviceType,
    nw,
    targetInterface,
    setTargetInterface,
    useDhcp,
    setUseDhcp,
    wf,
    setWf,
    ap,
    setAp,
    isScannable,
    wfList,
    infoMsg,
    apInfoMsg,
    handleIpInputChange,
    handleNetworkSettingIpInputChange,
    handleDhcpServerSettingIpInputChange,
    handleApplyClick,
    handleResetClick,
    handleWifiScanClick,
    handleSsidRowClick,
    handleWifiChangeSave,
    handleApChangeSave,
  };
};

export default useSettings;
