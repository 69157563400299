import Navigation from "./Navigation";

const Header = () => {
  return (
    <header className="flex items-center p-3 border-b fixed right-0 left-0 h-14 z-50 bg-white">
      <Navigation />
    </header>
  );
};

export default Header;
