import { setAccessToken } from "../api";
import jwt_decode from "jwt-decode";

export const getAccessToken = () => {
  const token = sessionStorage.getItem("accessToken");
  if (token) {
    const decoded: { exp: number } = jwt_decode(token);
    if (new Date(decoded.exp * 1000) < new Date()) {
      removeUserSession();
      return null;
    }
  }
  return token;
};

export const getRefreshToken = () => {
  return sessionStorage.getItem("refreshToken");
};

export const setUserSession = (accessToken: string) => {
  sessionStorage.setItem("accessToken", accessToken);

  setAccessToken(accessToken);
};

export const removeUserSession = () => {
  sessionStorage.removeItem("accessToken");
};
