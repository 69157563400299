import { useContext, useEffect, useRef, useState } from "react";
import Heading2 from "../shared/components/Heading2";
import { GetApiAsync } from "../api";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import Driver from "../shared/interfaces/Driver";
import { LOADING_STATUS } from "../shared/constants/status";
import { UrlContext } from "../App";
import InfoMessage from "../shared/components/InfoMessage";
import ErrorMessage from "../shared/components/ErrorMessage";
import { getAccessToken } from "../auth/SessionManager";

const Main = () => {
  const [drivers, setDrivers] = useState<Array<Driver>>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const host = useContext(UrlContext);

  const [socketConnected, setSocketConnected] = useState(false);
  let ws = useRef<WebSocket | null>(null);
  let status = useRef<LOADING_STATUS>("LOADING");

  useEffect(() => {
    fetch();
    return () => {
      //      console.log("clean up");
      setInfoMsg("socket disconnected.");
      ws.current?.close();
      ws.current = null;
      setSocketConnected(false);
    };
  }, []);

  useEffect(() => {
    if (socketConnected) {
      console.log("retry?",socketConnected);
      return;
    }

    if (!ws.current) {
      const accesstoken = getAccessToken();
      document.cookie = 'Authorization='+accesstoken+'; SameSite=Strict';
      const url = `ws://${host?.split("://")[1]}`+(process.env.REACT_APP_DEV_SERVER_URL===undefined?'':`?Authorization=${accesstoken}`);
      console.log(url);
      ws.current = new WebSocket(url);
      ws.current.onopen = () => {
        setInfoMsg("socket connected");
        setSocketConnected(true);
      };
      ws.current.onclose = (error) => {
        //console.log("disconnect from ", error);
        setInfoMsg("socket disconnected");
        ws.current = null;
        setSocketConnected(false);
      };
      ws.current.onerror = (error) => {
        console.log("connection error ", error);
        setInfoMsg("socket connection error");
      };
      ws.current.onmessage = (evt) => {
        if (status.current !== "LOADED")
          return;

        try {
          const data = JSON.parse(evt.data);
          if (!data.Type.includes("RealTimeDataPacket")) return;
          if (!Array.isArray(data.Data.Data)) return;

          for (var i = 0; i < data.Data.Data.length; i++) {
            let valueString = "";
            let rawValueString = "";

            if (data.Data.Data[i].Value != null && !isNaN(data.Data.Data[i].Value)) valueString = data.Data.Data[i].Value.toFixed(4);
            if (data.Data.Data[i].RawValue != null && !isNaN(data.Data.Data[i].RawValue)) rawValueString = data.Data.Data[i].RawValue.toFixed(4);

            document.getElementById("channel" + data.Data.Data[i].ChannelId + "_value")!.textContent = valueString;
            document.getElementById("channel" + data.Data.Data[i].ChannelId + "_rawValue")!.textContent = rawValueString;
          }
        } catch (error) {
          console.log(error);
          console.log("evt.data ", evt.data);
        }
      };
    }
  }, [socketConnected]);

  const fetch = async () => {
    status.current = "LOADING";
    const result = await GetApiAsync(`${host}/api/Home`);
    console.log(result.data);
    if (result.data) {
      setDrivers(result.data.drivers);
      setErrorMsg(result.data.errorMessage);
    } else {
      setErrorMsg("데이터가 없습니다");
    }
    status.current = "LOADED";
  };

  if (status.current === "LOADING") return <LoadingIndicator />;
  else {
    return (
      <div className="space-y-8">
        {infoMsg && <InfoMessage>{infoMsg}</InfoMessage>}
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        {drivers &&
          drivers.map((driver, index) => (
            <div key={index}>
              <Heading2>Driver: {driver.name}</Heading2>
              <div>
                {driver.channels.map((x, key) => (
                  <div key={key} className="flex items-center space-x-4">
                    <div className="inline bg-gray-100 font-semibold py-1 px-2 rounded-md">{x.name}</div>
                    <div className="inline" id={`channel${x.id}_value`}></div>
                    <div className="inline" id={`channel${x.id}_rawValue`}></div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    );
  }
};

export default Main;
