const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input
      className="w-full p-1 focus:outline-none border border-gray-300 rounded-sm bg-white disabled:bg-gray-100 disabled:text-gray-700 disabled:hover:cursor-not-allowed focus:border-black"
      {...props}
    />
  );
};

export default Input;
