import { useContext, useState } from "react";
import Heading2 from "../shared/components/Heading2";
import Input from "../shared/components/Input";
import SecondaryButton from "../shared/components/SecondaryButton";
import { PostApiAsync } from "../api";
import { setUserSession } from "../auth/SessionManager";
import { useNavigate } from "react-router-dom";
import { UrlContext } from "../App";
import { LOADING_STATUS } from "../shared/constants/status";
import LoadingIndicator from "../shared/components/LoadingIndicator";

const SignIn = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<LOADING_STATUS>("IDLE");
  const [pw, setPw] = useState("");
  const host = useContext(UrlContext);

  const onSave = async () => {
    if (pw === "") {
      alert("값을 입력하세요");
      return;
    }

    setStatus(() => "LOADING");

    const result = await PostApiAsync(`${host}/api/Auth/Login`, { password: pw }); //이거 괜찮나..

    console.log("result", result.data);
    if (result.data) {
      setUserSession(result.data);
      navigate("/", { replace: true });
    } else {
      alert("FAIL");
    }

    setStatus(() => "LOADED");
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-8 p-5">
      <Heading2>Enter Password</Heading2>
      {status === "LOADING" ? (
        <LoadingIndicator />
      ) : (
        <div className="min-w-60">
          <Input type="password" value={pw} onChange={(e) => setPw(e.target.value)} />
        </div>
      )}
      <SecondaryButton onClick={onSave}>Sign in</SecondaryButton>
    </div>
  );
};

export default SignIn;
