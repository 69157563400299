import styled from "styled-components";
import { MENU, MENU_TYPES } from "../constants/menu";
import { Dispatch, SetStateAction, useState } from "react";

const Navigation = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <nav className="hidden lg:block">
        <ul className="flex items-center space-x-4">
          <li>
            <a href={MENU[MENU_TYPES.DRIVERS].path}>
              <p className="font-bold">BDCon</p>
            </a>
          </li>
          <NavigationItem type={MENU_TYPES.NETWORK} />
          <NavigationItem type={MENU_TYPES.BDCON} />
          <NavigationItem type={MENU_TYPES.HARDWARE} />
          <NavigationItem type={MENU_TYPES.TEST} />
          <NavigationItem type={MENU_TYPES.PASSWORD} />
        </ul>
      </nav>
      <nav className="block lg:hidden">
        <div className="flex items-center space-x-4">
          <div>
            <a href={MENU[MENU_TYPES.DRIVERS].path}>
              <p className="font-bold">BDCon</p>
            </a>
          </div>
          <Hamburger onClick={() => setOpen(true)}>
            <label className="menu__btn">
              <span></span>
            </label>
          </Hamburger>
        </div>
      </nav>
     <Sidebar isopen={open} setIsOpen={setOpen} />
    </>
  );
};

const NavigationItem = ({ type }: { type: MENU_TYPES }) => {
  return (
    <li className="hover:bg-blue-100 ">
      <a href={MENU[type].path}>{MENU[type].text}</a>
    </li>
  );
};

const Sidebar = ({ isopen, setIsOpen }: { isopen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <StyledSidebar $show={isopen}>
      <div className="h-14 relative flex justify-end items-center">
        <button
          onClick={() => setIsOpen(false)}
          className="close-button text-[3rem] hover:cursor-pointer outline-none absolute right-4 font-extralight"
        >
          &times;
        </button>
      </div>
      <div className="bg-white h-full pt-20">
        <ul>
          <SidebarItem type={MENU_TYPES.NETWORK} />
          <SidebarItem type={MENU_TYPES.BDCON} />
          <SidebarItem type={MENU_TYPES.HARDWARE} />
          <SidebarItem type={MENU_TYPES.TEST} />
          <SidebarItem type={MENU_TYPES.PASSWORD} />
        </ul>
      </div>
    </StyledSidebar>
  );
};

const SidebarItem = ({ type }: { type: MENU_TYPES }) => {
  return (
    <a href={MENU[type].path}>
      <li className="border-b border-gray-300 py-4 mx-5 cursor-pointer text-lg">{MENU[type].text}</li>
    </a>
  );
};

export default Navigation;

const Hamburger = styled.div`
  .menu__btn {
    position: absolute;
    top: 24px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #616161;
    transition-duration: 0.25s;
  }
  .menu__btn > span::before {
    content: "";
    top: -8px;
  }
  .menu__btn > span::after {
    content: "";
    top: 8px;
  }
`;

export const StyledSidebar = styled.div<{ $show: boolean }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: white;

  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  opacity: ${({ $show }) => ($show ? "1" : "0")};
  transform: ${({ $show }) => ($show ? "translateX(0%)" : "translateX(100%)")};
  transition: transform 0.4s linear, opacity 0.2s linear 0.2s, visibility 0s linear 0.4s;
  transition-timing-function: ${({ $show }) =>
    $show ? "cubic-bezier(0,.2,.25,1), linear, linear" : "cubic-bezier(0.8, 0, 0.6, 1), linear, linear"};
  transition-delay: ${({ $show }) => ($show ? "0s" : "")};
  box-shadow: 0px 48px 100px rgba(17, 12, 46, 0.15);

  .close-button {
    transition: ${({ $show }) => ($show ? "all 0.5s cubic-bezier(1, -0.5, 1, 0.8)" : "0.1s")};
    opacity: ${({ $show }) => ($show ? "1" : "0")};
    color: ${({ $show }) => ($show ? "black" : "white")};
  }

  .submenu--open {
    max-height: 40rem;
    transition: visibility 0.5s, max-height 0.5s, transform 0.5s, opacity 0.4s;
  }
  .submenu--close {
    max-height: 0;
    transition: visibility 0.3s, max-height 0.3s, transform 0.3s, opacity 0.3s;
  }
`;
