import { useEffect, useState } from "react";
import Input from "./Input";
interface Props {
  ip: string | undefined | null;
  onChangeIp?: (props: { name: string; updated: string }) => void;
}

const IPInput = ({ ip, onChangeIp, ...props }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [splittedIp, setSplittedIp] = useState((ip ?? "...").split("."));

  useEffect(() => {
    setSplittedIp((ip ?? "...").split("."));
  }, [ip]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setSplittedIp([...splittedIp!.map((x, i) => (i === idx ? e.target.value : x))]);
    let newIp = [...splittedIp!.map((x, i) => (i === idx ? e.target.value : x))].join(".");
    onChangeIp && onChangeIp({ name: e.target.name, updated: newIp });
  };

  return (
    <div>
      <Input
        type="number"
        className="w-10 p-1 focus:outline-none border border-gray-300 rounded-sm bg-white disabled:bg-gray-100 disabled:text-gray-700 disabled:hover:cursor-not-allowed focus:border-black"
        {...props}
        onChange={(e) => onChange(e, 0)}
        value={splittedIp[0] ?? ""}
      />
      &nbsp;.&nbsp;
      <Input
        type="number"
        className="w-10 p-1 focus:outline-none border border-gray-300 rounded-sm bg-white disabled:bg-gray-100 disabled:text-gray-700 disabled:hover:cursor-not-allowed focus:border-black"
        {...props}
        onChange={(e) => onChange(e, 1)}
        value={splittedIp[1] ?? ""}
      />
      &nbsp;.&nbsp;
      <Input
        type="number"
        className="w-10 p-1 focus:outline-none border border-gray-300 rounded-sm bg-white disabled:bg-gray-100 disabled:text-gray-700 disabled:hover:cursor-not-allowed focus:border-black"
        {...props}
        onChange={(e) => onChange(e, 2)}
        value={splittedIp[2] ?? ""}
      />
      &nbsp;.&nbsp;
      <Input
        type="number"
        className="w-10 p-1 focus:outline-none border border-gray-300 rounded-sm bg-white disabled:bg-gray-100 disabled:text-gray-700 disabled:hover:cursor-not-allowed focus:border-black"
        {...props}
        onChange={(e) => onChange(e, 3)}
        value={splittedIp[3] ?? ""}
      />
    </div>
  );
};

export default IPInput;
