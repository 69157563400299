import SecondaryButton from "../shared/components/SecondaryButton";
import Heading2 from "../shared/components/Heading2";
import Input from "../shared/components/Input";
import PrimaryButton from "../shared/components/PrimaryButton";
import { useContext, useEffect, useState } from "react";
import { GetApiAsync, PostApiAsync } from "../api";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import ErrorMessage from "../shared/components/ErrorMessage";
import { LOADING_STATUS } from "../shared/constants/status";
import { UrlContext } from "../App";

interface Model {
  name: string;
  password: string;
  serverUrl: string;
  domain: string;
  errorMessages: Array<string>;
  successMessages: Array<string>;
  namePasswordCheckResult: boolean;
  serverUrlCheckResult: boolean;
}

const BDCon = () => {
  const [description, setDescription] = useState("");
  const [formData, setFormData] = useState<Model | null>(null);
  const [status, setStatus] = useState<LOADING_STATUS>("LOADING");
  const host = useContext(UrlContext);
  useEffect(() => {
    Check();
  }, []);

  const Check = async () => {
    setStatus(() => "LOADING");
    const { data } = await GetApiAsync(`${host}/api/BDCon`);
    setFormData(data);
    setStatus(() => "LOADED");
  };

  const Apply = async () => {
    const { data } = await PostApiAsync(`${host}/api/BDCon`, { ...formData });
    if (data.result) {
      setDescription("수정 성공");
    } else {
      setDescription(data.errorMessage);
    }
  };

  const onFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value } as Model);
  };

  if (status === "LOADED")
    return (
      <div className="space-y-8">
        <Heading2>BDCon Settings</Heading2>
        <div className="space-y-4">
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>ServerIP</p>
            <Input type="text" value={formData?.serverUrl} name="serverUrl" onChange={onFormDataChange} />
          </div>
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>Controller Name</p>
            <Input type="text" value={formData?.name} name="name" onChange={onFormDataChange} />
          </div>
          <div className="grid grid-cols-auto-fr gap-x-4 items-center">
            <p>Controller Password</p>
            <Input type="text" value={formData?.password} disabled />
          </div>
        </div>

        <div className="flex space-x-2">
          <SecondaryButton onClick={Check}>Check</SecondaryButton>
          <PrimaryButton onClick={Apply}>Apply</PrimaryButton>
        </div>
        <div className="space-y-1">
          {formData?.successMessages?.map((x, key) => (
            <p key={key} className="text-green-500">
              {x}
            </p>
          ))}
          {formData?.errorMessages?.map((x, key) => (
            <ErrorMessage key={key}>{x}</ErrorMessage>
          ))}
          <p className="text-blue-500">{description}</p>
        </div>
      </div>
    );
  else return <LoadingIndicator />;
};

export default BDCon;
